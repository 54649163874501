<template>
  <div class="relative mb-7 last:mb-0">
    <div class="flex">
      <div>
        <div class="bg-white p-3 rounded-lg text-base shadow">
          <div class="flex space-between items-center">
            <h2
              class="font-bold line-clamp-1 mb-2"
              :title="answer.question.title"
            >
              <NuxtLink
                :to="
                  localePath({
                    name: 'questionPage',
                    params: { questionSeoName: answer.question.seoName },
                  })
                "
              >
                {{ answer.question.title }}
              </NuxtLink>
            </h2>
          </div>
          <p class="line-clamp-2 mb-6">
            {{ answer.question.body }}
          </p>
          <p class="font-bold mb-2">
            {{
              isOwnProfile
                ? $t("professionalPage.topAnswers.myAnswer")
                : answer.person.name
            }}
          </p>
          <p class="mb-4">„{{ answer.body }}”</p>
          <div
            v-if="answer.linkedAnswer"
            class="ml-4 pl-2 border-l-2 border-primary text-sm mb-5"
          >
            <p>{{ answer.person.name }}:</p>
            <p v-if="!answer.linkedAnswer.deleted">
              {{ answer.linkedAnswer.body }}
            </p>
            <p v-else class="italic">
              {{ $t("professionalPage.topAnswers.linkedAnswerDeleted") }}
            </p>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex flex-row">
              <JoszakiButton
                size="sm"
                class="text-xs"
                icon-left="thumbs-up"
                :loading="isVoting"
                @click="upvoteClicked"
              >
                {{ answer.upvotes }}
              </JoszakiButton>
            </div>
            <p class="text-sm text-gray-500">
              {{ $d(new Date(answer.created), "shortDate") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAnswerVoter } from "~/composables/question/useUpvoteAnswer";
import { useProfessionalStore } from "~/stores/professional";

export default {
  props: {
    answer: {
      type: Object,
      default() {
        return {};
      },
    },
    isOwnProfile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { upvoteAnswer, loading } = useAnswerVoter();
    const professionalStore = useProfessionalStore();

    async function upvoteClicked() {
      const result = await upvoteAnswer(props.answer);

      if (result.answer) {
        const updatedAnswer = result.answer;
        professionalStore.$patch((state) => {
          state.person.topAnswers = state.person.topAnswers.map((answer) => {
            if (answer.id === updatedAnswer.id) {
              return {
                ...answer,
                ...updatedAnswer,
              };
            }
            return answer;
          });
        });
      }
    }

    return {
      upvoteClicked,
      isVoting: loading,
    };
  },
};
</script>
