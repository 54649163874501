<template>
  <section>
    <b-field
      v-for="field in descriptor"
      :key="field.value"
      :class="{
        'text-error': error,
      }"
    >
      <b-radio
        :value="value"
        :native-value="field.value"
        @input="$emit('input', field.value)"
      >
        {{ field.label }}
      </b-radio>
    </b-field>
    <span v-if="error" class="text-error">
      {{ error }}
    </span>
  </section>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      required: false,
      default: "",
    },
    // value-label pairs in an array
    descriptor: {
      type: Array,
      required: true,
    },
    // vertical or horizontal
    orientation: {
      type: String,
      default: "horizontal",
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
