<template>
  <div class="CardPersonWrapper flex flex-col items-center self-end">
    <!-- v-if="person.isActive || !person.isInactive" -->
    <div
      class="CardPersonActiveIndicator text-white flex items-center justify-center rounded-lg py-2 px-2 font-bold w-fit -mb-1.5"
      :class="{
        'bg-info': type === 'info',
        'bg-success': type === 'success',
        'bg-warning': type === 'warning',
        'bg-danger': type === 'danger',
        'bg-error': type === 'error',
      }"
    >
      <slot name="indicator" />
      <!-- <IconComponent
        icon="circle"
        class="!text-xs blink inline"
      />
      <span class="ml-2 uppercase">{{ $t('personProfile.openToOffers') }}</span> -->
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "info",
      validator: function (val) {
        return ["info", "success", "warning", "danger", "error"].includes(val);
      },
    },
  },
};
</script>
