<template>
  <section v-if="rankedProfessions && rankedProfessions.length">
    <h1 class="section-title">
      {{ $t("personProfile.myProfessions") }}
    </h1>
    <template v-for="prof in rankedProfessions">
      <NuxtLink
        v-if="isJoszaki"
        :key="prof.id"
        class="person-profession"
        :to="
          localePath({
            name: 'professionPage',
            params: { profession: prof.seoName },
          })
        "
      >
        <b-icon icon="briefcase" />
        {{ prof.name }}
        <small class="text-xs">
          <b-icon icon="check" class="text-success-darker" />
          <span class="profession-wincount">
            {{ prof.winCount }}
            {{ $t("personProfile.professionWinCount") }}
          </span>
        </small>
      </NuxtLink>
      <span v-else :key="prof.id" class="person-profession">
        <b-icon icon="briefcase" />
        {{ prof.name }}
      </span>
    </template>
  </section>
</template>

<script>
import { mapState } from "pinia";
import { useProfessionalStore } from "~/stores/professional";
import { useBrand } from "~/composables/useBrand";

export default {
  setup() {
    const { isJoszaki } = useBrand();
    return {
      isJoszaki,
    };
  },
  computed: {
    ...mapState(useProfessionalStore, {
      rankedProfessions: "rankedProfessions",
    }),
  },
};
</script>

<style lang="scss" scoped>
.person-profession {
  font-size: 13px;
  background: #fff;
  display: inline-block;
  padding: 0.2rem 0.4rem;
  border-radius: 0.4rem;
  margin-right: 0.2rem;
  margin-bottom: 10px;
  border: 2px solid #3397db;
  color: #337ab7;

  &:hover {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: #23527c;
    text-decoration: none;
  }

  &:active,
  &:hover {
    outline: 0;
  }

  &:hover {
    background: #e0effa;
  }
}

.section-title {
  color: #2e72ad;
  margin-bottom: 0.8rem;
  font-weight: 700;
  font-size: 2rem;
}
</style>
