<template>
  <div class="gallery">
    <div class="images">
      <div
        v-for="(image, index) in imageList"
        :key="image.src.replace('.jpg', '') + index"
        class="image"
        :title="'index:' + index"
        :style="{ backgroundImage: 'url(' + image.thumb + ')' }"
        @click="imageIndex = index"
      />
    </div>
    <CoolLightBox
      id="z"
      :items="imageList"
      :index="imageIndex"
      @close="imageIndex = null"
    />
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    CoolLightBox,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageIndex: null,
    };
  },
  computed: {
    // whenever question changes, this function will run
    imageList() {
      const list = [];
      for (const image of this.images) {
        const element = {
          thumb: image.urlThumbnail,
          src: image.urlNormal,
          description: image.name,
        };
        list.push(element);
      }
      return list;
    },
  },
};
</script>

<style scoped>
.cool-lightbox {
  top: 54px;
}

#z >>> .vue-lb-container {
  z-index: 30 !important;
}

.image {
  width: 85px;
  height: 85px;
  padding: 5px;
  background-size: cover;
  background-position: center center;
  display: inline-block;
  margin-right: 1em;
  border-radius: 0.5rem;
  cursor: pointer;
}
</style>
