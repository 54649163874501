import { ref, useContext } from "@nuxtjs/composition-api";
import { storeToRefs } from "pinia";
import { useUserStore } from "~/stores/user";
import UPVOTE_ANSWER from "~/stores/question/mutations/upvoteAnswer.graphql";
import DOWNVOTE_ANSWER from "~/stores/question/mutations/downvoteAnswer.graphql";
import REMOVE_VOTE_FROM_ANSWER from "~/stores/question/mutations/removeVoteFromAnswer.graphql";

export function useAnswerReactionRequests() {
  const { $mutate } = useContext();

  function upvoteAnswerRequest(answerId) {
    return $mutate(UPVOTE_ANSWER, { answerId });
  }
  function downvoteAnswerRequest(answerId) {
    return $mutate(DOWNVOTE_ANSWER, { answerId });
  }
  function removeVoteFromAnswerRequest(answerId) {
    return $mutate(REMOVE_VOTE_FROM_ANSWER, {
      answerId,
    });
  }
  return {
    upvoteAnswerRequest,
    downvoteAnswerRequest,
    removeVoteFromAnswerRequest,
  };
}

export function useAnswerVoter() {
  const loading = ref(false);
  const { $joszaki, $sentry, i18n } = useContext();
  const userStore = useUserStore();
  const { me } = storeToRefs(userStore);
  const { upvoteAnswerRequest, removeVoteFromAnswerRequest } =
    useAnswerReactionRequests();

  /**
   * Encapsulates logic pressing the upvote button on an answer
   * If user is not logged in a toast message is displayed instructing the user to login
   * If a vote already exists for the user, the vote is removed, otherwise a new vote is created
   * Every time the modified answer is returned
   */
  async function upvoteAnswer(answer) {
    if (!me.value) {
      $joszaki.toast({
        message: i18n.t("questionPage.answerCard.onlyLoggedInCanVote"),
        type: "info",
      });
      return {
        answer: null,
      };
    }
    try {
      loading.value = true;
      const existingVote = answer.votes.find(
        (vote) => vote.personId === me.value.id
      );

      if (existingVote) {
        return await removeVoteFromAnswerRequest(answer.id);
      } else {
        return await upvoteAnswerRequest(answer.id);
      }
    } catch (err) {
      $sentry.captureException(err, {
        tags: {
          questions: "upvote",
        },
      });
      $joszaki.toast({
        type: "error",
        message: i18n.t("questionsPage.vote.somethingWentWrongMessage"),
      });
    } finally {
      loading.value = false;
    }
  }

  return {
    upvoteAnswer,
    loading,
  };
}
