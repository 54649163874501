var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"CardPersonWrapper flex flex-col items-center self-end"},[_c('div',{staticClass:"CardPersonActiveIndicator text-white flex items-center justify-center rounded-lg py-2 px-2 font-bold w-fit -mb-1.5",class:{
      'bg-info': _vm.type === 'info',
      'bg-success': _vm.type === 'success',
      'bg-warning': _vm.type === 'warning',
      'bg-danger': _vm.type === 'danger',
      'bg-error': _vm.type === 'error',
    }},[_vm._t("indicator")],2),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }