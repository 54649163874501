<template>
  <div class="card">
    <PersonPictureSet
      class="profile-picture-new w-full h-auto block max-w-[280px] mx-auto"
      :person-id="person.id"
      :alt="person.name"
      @error="handlePictureError"
    />
    <RatingIndicator
      :rate="person.score / 2"
      :score="person.score"
      :disabled="true"
      class="rating-stars"
    />
    <div class="person-name">
      {{ person.name }}
    </div>
    <p
      v-if="topRankedProfessions?.length"
      class="font-bold text-sm align-middle"
    >
      <IconComponent icon="briefcase" class="inline mr-1" />
      <template v-for="(profession, index) in topRankedProfessions">
        {{ profession.name }}
        {{ index !== topRankedProfessions.length - 1 ? " · " : "" }}
      </template>
    </p>
    <div class="content text-center has-smaller-text">
      <PersonStats :person="person" />
      <p>
        {{ $t("profileCard.lastSeen", { timeAgo: dateDifferenceToNow }) }}
      </p>

      <p v-if="!cityFromQuery">
        <b-icon icon="map-marker-alt" />
        <template v-if="person.goToHouse">
          <template v-if="goToWholeCountry">
            {{ $t("personProfile.location.everywhere") }}
          </template>
          <template v-else>
            <i18n path="personProfile.location.goToHouse">
              <template #city>
                <b>{{ person.city.name }}</b>
              </template>
              <template #km>
                <b>
                  {{ person.goToHouseKm }}
                </b>
              </template>
            </i18n>
          </template>
        </template>
        <template v-else>
          <i18n path="personProfile.location.inCity">
            <template #city>
              <b>{{ person.city.name }}</b>
            </template>
          </i18n>
        </template>
      </p>
      <p v-else-if="cityFromQuery">
        <b-icon icon="check" class="text-[#1fbb00]" />
        <b>
          <span>{{
            $t("workThere.text", { cityName: cityFromQuery.name })
          }}</span>
        </b>
      </p>

      <p v-if="person.phoneNumber">
        <b-icon icon="phone-alt" />
        {{ $helpers.formatPhoneNumber(person.phoneNumber) }}
      </p>
      <b-button
        type="is-info"
        class="fast-tender"
        @click="$emit('requestTender')"
      >
        <b>{{ $t("profileCard.requestTender") }}</b
        ><br />
        <small>({{ $t("profileCard.quickOfferSubtitle") }})</small>
      </b-button>

      <div v-if="!isSuperprofikCz" class="user-social-box">
        <div class="facebook-box">
          <FacebookLike />
        </div>
        <div class="facebook-box">
          <FacebookShare :path="$route.path" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "pinia";
import PersonStats from "./PersonStats.vue";
import FacebookShare from "~/components/thirdParty/FacebookShare.vue";
import FacebookLike from "~/components/thirdParty/FacebookLike.vue";
import PersonPictureSet from "~/components/PersonPictureSet.vue";
import RatingIndicator from "~/components/people/RatingIndicator.vue";
import { differenceToNow } from "~/helpers/date";
import { useProfessionalStore } from "~/stores/professional";
import { useBrand } from "~/composables/useBrand";

export default {
  components: {
    PersonStats,
    FacebookShare,
    FacebookLike,
    PersonPictureSet,
    RatingIndicator,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { isSuperprofikCz } = useBrand();
    return {
      isSuperprofikCz,
      professionalStore: useProfessionalStore(),
    };
  },
  computed: {
    ...mapState(useProfessionalStore, {
      rankedProfessions: "rankedProfessions",
    }),
    dateDifferenceToNow() {
      if (!this.person?.lastLogin) {
        return null;
      }
      const lastHere = DateTime.fromISO(this.person.lastLogin).diff(
        DateTime.now(),
        "days"
      );

      // if was here in the past 5 years return null
      if (lastHere > 5 * 356) {
        return null;
      }

      return differenceToNow(this, this.person?.lastLogin);
    },
    goToWholeCountry() {
      return this.person?.goToHouseKm > 500;
    },
    topRankedProfessions() {
      return this.rankedProfessions.slice(0, 3);
    },
    cities() {
      return this.$store.getters["data/cities"];
    },
    cityFromQuery() {
      if (this.$route.query && this.$route.query.city) {
        const matchedCities = this.cities.filter(
          (c) => c.seoName === this.$route.query.city
        );

        if (matchedCities.length > 0) {
          return matchedCities[0];
        }
      }

      return null;
    },
  },
  methods: {
    handlePictureError() {
      this.professionalStore.pictureError();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-flow: column;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;
}

.profile-picture {
  display: block;
  margin: 0 0 0 1rem;
  -webkit-clip-path: circle(55% at 60% 40%);
  clip-path: circle(55% at 60% 40%);
  margin-right: -1rem;
  margin-top: -1rem;
  max-width: 300px;
  margin-left: auto;
}

.profile-picture-new {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
}

.rating-stars {
  padding-bottom: 5px;
}
.person-name {
  font-weight: 800;
  text-align: left;
  font-family: nunito, sans-serif;
  font-size: 1.1em;
  margin-bottom: 1rem;
}

.fast-tender {
  padding: 2rem 1rem;
  width: 100%;
}

.user-social-box {
  display: table;
  width: 100%;
  border-top: #e4e4e4;
  border-bottom: #e4e4e4;
  position: relative;
  font-size: 1rem;
  margin-top: 1rem;

  &:before {
    background: #e4e4e4;
    position: absolute;
    content: "";
    display: block;
    left: 50%;
    width: 1px;
    height: 100%;
  }
}

.facebook-box {
  display: table-cell;
  vertical-align: middle;
  height: 1rem;
  text-align: center;
  width: 50%;
}

.has-smaller-text {
  font-size: 14px !important;
}
</style>
