<template>
  <b-modal v-model="showReportPerson" :width="600">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $t("reportMate.header") }}
          </p>
          <button type="button" class="delete" @click="close" />
        </header>
        <section class="modal-card-body">
          <div class="content block">
            <h1 class="title is-5">
              {{ $t("reportMate.title") }}
            </h1>

            <h2 class="title is-6 mt-4">
              {{ $t("reportMate.chooseOption") }}
            </h2>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <RadioButtons
                v-model="reason"
                :error="errors[0]"
                class="text-base ml-4"
                :descriptor="[
                  {
                    value: 'underage',
                    label: $t('reportMate.options.underage'),
                  },
                  {
                    value: 'notreal',
                    label: $t('reportMate.options.notreal'),
                  },
                  {
                    value: 'wrongProfessions',
                    label: $t('reportMate.options.wrongProfessions'),
                  },
                  {
                    value: 'other',
                    label: $t('reportMate.options.other'),
                  },
                ]"
              />
            </ValidationProvider>
            <TextInput
              v-if="reason === 'other'"
              v-model="message"
              type="textarea"
              class="mt-4 ml-4"
              :rules="{
                required: true,
              }"
            />
            <JoszakiInputOld
              v-model="email"
              type="email"
              class="mt-8"
              :rules="{
                required: true,
              }"
              :label="$t('reportMate.email')"
              @keydown.native.enter="handleSubmit(submit)"
            />
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button
            expanded
            type="is-success"
            :loading="loading"
            :disabled="loading"
            @click="handleSubmit(submit)"
          >
            {{ $t("reportMate.submit") }}
          </b-button>
        </footer>
      </div>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import RadioButtons from "~/components/inputs/RadioButtons.vue";
import TextInput from "~/components/inputs/TextInput.vue";
import { useProfessionalStore } from "~/stores/professional";

export default {
  components: {
    RadioButtons,
    TextInput,
  },
  setup() {
    return {
      professionalStore: useProfessionalStore(),
    };
  },
  data() {
    return {
      email: "",
      reason: "",
      message: "",
      loading: false,
      showReportPerson: false,
    };
  },
  mounted() {
    this.$nuxt.$on("report-person", this.open);
  },
  beforeDestroy() {
    this.$nuxt.$off("report-person", this.open);
  },
  methods: {
    async submit() {
      this.loading = true;
      const reasonString =
        this.reason === "other"
          ? this.message
          : this.$t(`reportMate.options.${this.reason}`);
      const message = this.$t("reportMate.message", {
        email: this.email,
        reason: reasonString,
        mateUrl: `${this.$helpers.topDomain}${this.$route.fullPath}`,
      });
      try {
        await this.professionalStore.reportPerson(message);
        this.$joszaki.alert({
          message: this.$t("reportMate.success"),
          type: "success",
        });
        this.close();
      } catch (err) {
        if (typeof err === "string") {
          this.$buefy.toast.open({
            message: err,
            type: "is-danger",
          });
        } else {
          this.$sentry.captureException(err, {
            tags: {
              profile: "report_mate",
            },
          });
        }
      } finally {
        this.loading = false;
      }
    },
    open() {
      this.showReportPerson = true;
      this.email = "";
      this.reason = "";
      this.message = "";
    },
    close() {
      this.showReportPerson = false;
    },
  },
};
</script>
