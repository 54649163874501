<template>
  <client-only>
    <div
      class="fb-share-button"
      :data-href="url"
      data-layout="button_count"
      data-size="large"
    >
      <a
        target="_blank"
        :href="`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl};src=sdkpreparse`"
        class="fb-xfbml-parse-ignore"
        >Share</a
      >
    </div>
  </client-only>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  head() {
    return {
      script: [
        {
          async: true,
          crossorigin: "anonymous",
          src: "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0",
          nonce: "9WoJ2dL2",
        },
      ],
    };
  },
  computed: {
    url() {
      return `${this.$helpers.topDomain}${this.path}`;
    },
    encodedUrl() {
      return encodeURI(this.url);
    },
  },
};
</script>
