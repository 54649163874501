<template>
  <div class="sticky-panel">
    <div class="columns is-multiline">
      <div class="column is-full is-half-widescreen">
        <a @click="onClickRequestTender()">
          <div class="profile-panel">
            <div class="panel-title">
              {{ $t("sticky.requestTender") }}
            </div>
            <div class="panel-subtitle">
              {{ $t("sticky.oneMinAndFree") }}
            </div>
            <div class="panel-icon">
              <b-icon icon="play" />
            </div>
          </div>
        </a>
      </div>

      <div class="column is-full is-half-widescreen">
        <a
          v-if="person.phoneNumber?.number"
          class="phone-panel"
          :href="$helpers.getPhoneNumberLink(person.phoneNumber)"
          @click="onClickPhoneNumber"
        >
          <div class="profile-panel">
            <div
              v-if="$ab.isVariant('phone-extension-display', 'variant1')"
              class="panel-title"
            >
              {{ phoneNumber }}
              <span v-if="phoneNumberExtension">
                <span class="text-xs">{{
                  $t("sticky.afterMusicCallExtension")
                }}</span>
                {{ phoneNumberExtension }}
              </span>
            </div>
            <div v-else class="panel-title">
              {{ $helpers.formatPhoneNumber(person.phoneNumber) }}
            </div>
            <div
              v-if="$ab.isVariant('phone-extension-display', 'variant1')"
              class="panel-subtitle"
            >
              {{ $t("sticky.callMeVariant1") }} {{ phoneNumberExtension }}
            </div>
            <div v-else class="panel-subtitle">
              {{ $t("sticky.callMeControl") }}
            </div>
            <div class="panel-icon">
              <b-icon icon="phone-alt" />
            </div>
            <div class="phone-info">
              <b-tooltip
                position="is-top"
                multilined
                type="is-white"
                :label="$t('sticky.phoneTooltip')"
              >
                <b-icon icon="info-circle" /> {{ $t("sticky.phoneHint") }}
              </b-tooltip>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  computed: {
    phoneNumber() {
      return this.$helpers.formatPhoneNumber({
        number: this.person.phoneNumber.number.split(",")[0],
        countryCode: this.person.phoneNumber.countryCode,
      });
    },
    phoneNumberExtension() {
      return this.$helpers.getPhoneNumberExtension(
        this.person.phoneNumber.number
      );
    },
  },
  methods: {
    onClickPhoneNumber() {
      this.$trackers.trackEvent({
        title: "Clicked on Phone Number on Profile",
        data: {
          personId: this.person?.id,
          personSeoName: this.person?.seoName,
        },
      });
      this.$track.customEvent({
        event: "initiate_call",
        ecommerce: { items: [this.person.id] },
      });
    },
    onClickRequestTender() {
      this.$emit("requestTender");
    },
  },
};
</script>

<style lang="scss" scoped>
@use "styles/_all-members" as global;

.sticky-panel {
  position: sticky;
  top: 0.5rem;
  z-index: 2;
}

.phone-panel {
  .profile-panel {
    background-color: global.$primary;
  }
}

.profile-panel {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  box-shadow: 0.08em 0.25em 0.4em rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  padding: 0.5rem 0.5rem;
  z-index: 2;

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto 30px;
  grid-template-areas:
    "title icon"
    "subtitle icon";

  &:hover {
    box-shadow: 0.04em 0.1em 0.2em rgba(0, 0, 0, 0.35);
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
    text-decoration: none;
  }
}

.panel-title {
  grid-area: title;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Noto Sans", "Arial", sans-serif;

  &:not(.\!text-primary-light) {
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.35);
  }

  @include global.mobile {
    font-size: 1rem;
  }
}

.panel-subtitle {
  grid-area: subtitle;
}

.panel-icon {
  grid-area: icon;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: inherit;
  width: 30px;
}

.phone-panel {
  position: relative;
  z-index: 2;

  &:hover {
    .profile-panel {
      background-color: #286090;
      border-color: #204d74;
    }
  }
}

.phone-info {
  position: absolute;
  z-index: 2;
  bottom: -30px;
  color: black;
  right: 5px;
}
</style>
