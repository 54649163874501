<template>
  <div
    v-if="price"
    class="border-b-gray-500 border-dotted [&:not(:last-child)]:border-b pb-4 [&:not(:first-child)]:pt-4"
  >
    <div v-if="price.priceTag" class="grid grid-cols-1 md:grid-cols-[1fr_auto]">
      <h1 class="text-xl font-bold text-primary-light mb-2">
        {{ price.priceTag.name }}
      </h1>
      <div class="mb-2 text-gray-600 hover:text-gray-600">
        <span class="font-bold">
          {{ formatTaskPrice(price) }}
        </span>
      </div>
      <span
        class="text-gray-500 block whitespace-pre-wrap md:col-span-2"
        v-text="price.description"
      />
    </div>
    <NuxtLink
      v-if="price.professionTask"
      :to="
        localePath({
          name: 'prices',
          params: {
            professionTask: price.professionTask.seoName,
          },
        })
      "
      class="grid grid-cols-[1fr_auto]"
    >
      <h1 class="text-xl font-bold text-primary-light mb-2">
        {{ $helpers.capitalize(price.professionTask.name) }}
      </h1>
      <div class="mb-2 text-gray-600 hover:text-gray-600">
        <span class="font-bold">
          {{ formatTaskPrice(price) }}
        </span>
      </div>
      <span
        class="text-gray-500 block whitespace-pre-wrap md:col-span-2"
        v-text="price.description"
      />
    </NuxtLink>
  </div>
</template>

<script>
import { useTaskPriceFormatters } from "~/composables/taskPriceFormatters";

export default {
  props: {
    price: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { formatTaskPrice } = useTaskPriceFormatters();

    return {
      formatTaskPrice,
    };
  },
};
</script>
