var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"width":600},model:{value:(_vm.showReportPerson),callback:function ($$v) {_vm.showReportPerson=$$v},expression:"showReportPerson"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.$t("reportMate.header"))+" ")]),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":_vm.close}})]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"content block"},[_c('h1',{staticClass:"title is-5"},[_vm._v(" "+_vm._s(_vm.$t("reportMate.title"))+" ")]),_c('h2',{staticClass:"title is-6 mt-4"},[_vm._v(" "+_vm._s(_vm.$t("reportMate.chooseOption"))+" ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('RadioButtons',{staticClass:"text-base ml-4",attrs:{"error":errors[0],"descriptor":[
                {
                  value: 'underage',
                  label: _vm.$t('reportMate.options.underage'),
                },
                {
                  value: 'notreal',
                  label: _vm.$t('reportMate.options.notreal'),
                },
                {
                  value: 'wrongProfessions',
                  label: _vm.$t('reportMate.options.wrongProfessions'),
                },
                {
                  value: 'other',
                  label: _vm.$t('reportMate.options.other'),
                },
              ]},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})]}}],null,true)}),(_vm.reason === 'other')?_c('TextInput',{staticClass:"mt-4 ml-4",attrs:{"type":"textarea","rules":{
              required: true,
            }},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}):_vm._e(),_c('JoszakiInputOld',{staticClass:"mt-8",attrs:{"type":"email","rules":{
              required: true,
            },"label":_vm.$t('reportMate.email')},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return handleSubmit(_vm.submit)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"expanded":"","type":"is-success","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t("reportMate.submit"))+" ")])],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }