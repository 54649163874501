import { render, staticRenderFns } from "./CardPersonActiveIndicator.vue?vue&type=template&id=08e2fe54&"
import script from "./CardPersonActiveIndicator.vue?vue&type=script&lang=js&"
export * from "./CardPersonActiveIndicator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports