import { render, staticRenderFns } from "./CardScore.vue?vue&type=template&id=9dcb206e&scoped=true&"
import script from "./CardScore.vue?vue&type=script&lang=js&"
export * from "./CardScore.vue?vue&type=script&lang=js&"
import style0 from "./CardScore.vue?vue&type=style&index=0&id=9dcb206e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dcb206e",
  null
  
)

export default component.exports