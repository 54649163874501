var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.person.scores.length ||
    _vm.person.prices.length ||
    _vm.person.photos.length ||
    _vm.person.topAnswers.length
  )?_c('div',[_c('div',{staticClass:"flex overflow-x-auto"},_vm._l((_vm.tabs),function(tab,index){return _c('a',{key:tab.hash,staticClass:"text-lg px-1 md:!px-4 !py-3 cursor-pointer font-bold rounded-t-md",class:{
        'bg-gray-600/5 !text-primary': index === _vm.activeTab,
        'hover:bg-gray-600/5 text-black': index !== _vm.activeTab,
      },attrs:{"href":`#${tab.hash}`},on:{"click":function($event){return _vm.$trackers.trackClick('ProfileTabsLink', { name: tab.hash })}}},[_vm._v(" "+_vm._s(tab.label)+" ")])}),0),_c('div',{staticClass:"bg-gray-600/5 p-4 rounded-md",class:{
      'rounded-tl-none': _vm.$route.hash === '#description' || !_vm.$route.hash,
    }},[_c('div',{staticClass:"hidden flex-col gap-8",class:{
        '!flex': _vm.$route.hash === '#description' || !_vm.$route.hash,
      }},[_c('p',{ref:"memo",class:{
          'line-clamp-4': !_vm.memoExpanded,
        }},[_vm._v(" "+_vm._s(_vm.person.memo)+" ")]),(_vm.showMoreVisible && !_vm.memoExpanded)?_c('JoszakiButton',{attrs:{"icon-left":"plus","size":"sm","inverted":true,"outline":true},on:{"click":function($event){_vm.memoExpanded = true}}},[_vm._v(" Mutass többet ")]):_vm._e()],1),_c('div',{staticClass:"hidden flex-col gap-8",class:{
        '!flex': _vm.$route.hash === '#ratings',
      }},_vm._l((_vm.person.scores),function(score){return _c('ProfileRating',{key:score.id,attrs:{"score":score}})}),1),_c('div',{staticClass:"hidden flex-col gap-8",class:{
        '!flex': _vm.$route.hash === '#prices',
      }},[_c('CardProfilePrices',{staticClass:"section-card"})],1),_c('div',{staticClass:"hidden flex-col gap-8",class:{
        '!flex': _vm.$route.hash === '#references',
      }},[_c('PersonReferences',{staticClass:"section-card",attrs:{"photos":_vm.person.photos}})],1),_c('div',{staticClass:"hidden flex-col gap-8",class:{
        '!flex': _vm.$route.hash === '#answers',
      }},_vm._l((_vm.person.topAnswers),function(answer){return _c('AnswerInset',{key:answer.id,attrs:{"answer":answer,"is-own-profile":true}})}),1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }