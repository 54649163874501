<template>
  <div
    class="fb-like"
    data-href="https://facebook.com/joszaki"
    data-width=""
    data-layout="button_count"
    data-action="like"
    data-size="large"
    data-share="false"
  />
</template>

<script>
export default {
  head() {
    return {
      script: [
        {
          async: true,
          crossorigin: "anonymous",
          src: "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0",
          nonce: "o4sZ7qdi",
        },
      ],
    };
  },
};
</script>
