<template>
  <div class="user-stats">
    <div class="user-stat">
      <span style="font-weight: bold">{{ person.winCount || 0 }}</span>
      <div class="stat-title text-base">
        <b-icon icon="check" class="text-success-darker" />
        {{ $t("statCard.won") }}
      </div>
    </div>
    <div class="user-stat">
      <b>{{ scoreCount }}</b>
      <div class="stat-title text-base">
        <b-icon icon="star" />
        <a href="#ratings">
          {{ $t("statCard.reviews") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  computed: {
    scoreCount() {
      return this.person.scores.filter((x) => !x.deleted).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-stats {
  display: table;
  width: 100%;
  border-top: #e4e4e4;
  border-bottom: #e4e4e4;
  position: relative;
  font-size: 1.5em;

  &:before {
    background: #e4e4e4;
    position: absolute;
    content: "";
    display: block;
    left: 50%;
    width: 1px;
    height: 100%;
  }
}

.user-stat {
  display: table-cell;
  vertical-align: middle;
  height: 4em;
  text-align: center;
  width: 50%;
}

.stat-title {
  .icon {
    color: #ffc107;
  }
}
</style>
