<template>
  <div
    v-if="
      person.scores.length ||
      person.prices.length ||
      person.photos.length ||
      person.topAnswers.length
    "
  >
    <div class="flex overflow-x-auto">
      <a
        v-for="(tab, index) in tabs"
        :key="tab.hash"
        :href="`#${tab.hash}`"
        class="text-lg px-1 md:!px-4 !py-3 cursor-pointer font-bold rounded-t-md"
        :class="{
          'bg-gray-600/5 !text-primary': index === activeTab,
          'hover:bg-gray-600/5 text-black': index !== activeTab,
        }"
        @click="$trackers.trackClick('ProfileTabsLink', { name: tab.hash })"
      >
        {{ tab.label }}
      </a>
    </div>
    <div
      class="bg-gray-600/5 p-4 rounded-md"
      :class="{
        'rounded-tl-none': $route.hash === '#description' || !$route.hash,
      }"
    >
      <div
        class="hidden flex-col gap-8"
        :class="{
          '!flex': $route.hash === '#description' || !$route.hash,
        }"
      >
        <p
          ref="memo"
          :class="{
            'line-clamp-4': !memoExpanded,
          }"
        >
          {{ person.memo }}
        </p>
        <JoszakiButton
          v-if="showMoreVisible && !memoExpanded"
          icon-left="plus"
          size="sm"
          :inverted="true"
          :outline="true"
          @click="memoExpanded = true"
        >
          Mutass többet
        </JoszakiButton>
      </div>
      <div
        class="hidden flex-col gap-8"
        :class="{
          '!flex': $route.hash === '#ratings',
        }"
      >
        <ProfileRating
          v-for="score in person.scores"
          :key="score.id"
          :score="score"
        />
      </div>
      <div
        class="hidden flex-col gap-8"
        :class="{
          '!flex': $route.hash === '#prices',
        }"
      >
        <CardProfilePrices class="section-card" />
      </div>
      <div
        class="hidden flex-col gap-8"
        :class="{
          '!flex': $route.hash === '#references',
        }"
      >
        <PersonReferences :photos="person.photos" class="section-card" />
      </div>
      <div
        class="hidden flex-col gap-8"
        :class="{
          '!flex': $route.hash === '#answers',
        }"
      >
        <AnswerInset
          v-for="answer in person.topAnswers"
          :key="answer.id"
          :answer="answer"
          :is-own-profile="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardProfilePrices from "./CardProfilePrices.vue";
import ProfileRating from "./ProfileRating.vue";
import AnswerInset from "~/components/_refactored/profile/AnswerInset.vue";
import PersonReferences from "~/components/profile/PersonReferences.vue";
import { useProfessionalStore } from "~/stores/professional";

export default {
  name: "TabsLayout",
  components: {
    ProfileRating,
    CardProfilePrices,
    PersonReferences,
    AnswerInset,
  },
  setup() {
    const professionalStore = useProfessionalStore();
    return {
      professionalStore,
    };
  },
  data() {
    return {
      showMoreVisible: false,
      memoExpanded: false,
    };
  },
  computed: {
    person() {
      return this.professionalStore.person;
    },
    tabs() {
      const tabs = [];
      tabs.push({
        label: "Leírás",
        hash: "description",
      });
      if (this.person?.photos?.length) {
        tabs.push({
          label: "Referenciáim",
          hash: "references",
        });
      }
      if (this.person?.scores?.length) {
        tabs.push({
          label: "Értékeléseim",
          hash: "ratings",
        });
      }
      if (this.person?.prices?.length) {
        tabs.push({
          label: "Áraim",
          hash: "prices",
        });
      }
      if (this.person?.topAnswers?.length) {
        tabs.push({
          label: "Válaszaim",
          hash: "answers",
        });
      }
      return tabs;
    },
    activeTab() {
      return this.$route.hash
        ? this.tabs.findIndex((tab) => tab.hash === this.$route.hash.slice(1))
        : 0;
    },
  },
  mounted() {
    if (this.$refs.memo) {
      this.showMoreVisible =
        this.$refs.memo.scrollHeight > this.$refs.memo.clientHeight;
    }
  },
  methods: {
    // selectTab (index) {
    //   this.activeTab = index
    //   // this.$emit('select', index)
    // },
  },
};
</script>
