<template>
  <div class="flex flex-col items-stretch">
    <NuxtLink
      v-slot="{ navigate }"
      :to="
        localePath({
          name: 'professionalRatingPage',
          params: {
            professionalSeoName: person.seoName,
          },
        })
      "
    >
      <b-button type="is-info" outlined @click="navigate">
        {{ $t("profileAction.rateMate") }}
      </b-button>
    </NuxtLink>
    <div class="profile-report">
      <a class="text-xs" @click="reportPerson">
        {{ $t("profileAction.reportMate") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  methods: {
    reportPerson() {
      this.$nuxt.$emit("report-person");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-report {
  text-align: center;
}
</style>
