<template>
  <ValidationProvider
    ref="provider"
    v-slot="{ failed, errors }"
    :rules="validationRules"
    slim
  >
    <b-field
      :label="label"
      :type="failed ? 'is-danger' : status"
      :message="errors[0]"
    >
      <b-input
        :value="value"
        :type="type"
        :placeholder="placeholder"
        :aria-placeholder="placeholder"
        @input="updateValue"
      />
    </b-field>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    //
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    validationRules() {
      let defaultRules;
      if (this.type === "email") {
        defaultRules = {
          email: true,
        };
      }
      return Object.assign({}, defaultRules, this.rules);
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
