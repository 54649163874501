<template>
  <div class="flex flex-row gap-1 items-center text-yellow-400 font-bold">
    <span class="flex flex-row pb-1">
      <IconComponent
        v-for="n in ratingInt"
        :key="`full-${n}`"
        icon="star"
        class="text-yellow-400"
      />
      <IconComponent
        v-if="hasHalfStar"
        icon="star-half-alt"
        class="text-yellow-400"
      />
      <IconComponent
        v-for="n in ratingPadding"
        :key="`empty-${n}`"
        icon="star"
        pack="far"
        class="text-yellow-400"
      />
    </span>
    <span>{{ fixedRating }}</span>
  </div>
</template>

<script>
export default {
  name: "PersonRating",
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ratingInt() {
      return Math.floor(this.person.score / 2);
    },
    hasHalfStar() {
      return Math.round(this.person.score) % 2 === 1;
    },
    ratingPadding() {
      return 5 - (this.ratingInt + (this.hasHalfStar ? 1 : 0));
    },
    fixedRating() {
      return (this.person.score / 2).toFixed(1);
    },
  },
};
</script>
