<template>
  <div v-if="prices" class="shadow-md rounded-xl bg-white p-4">
    <CardProfilePricesPrice
      v-for="price in visiblePrices"
      :key="price.id"
      :price="price"
    />
    <JoszakiButton
      v-if="toShowCount < prices.length"
      type="info"
      expanded
      class="mt-4"
      @click="showMore"
    >
      {{ $t("pricesCard.showMore") }}
    </JoszakiButton>
  </div>
</template>

<script>
import CardProfilePricesPrice from "~/components/_refactored/profile/CardProfilePricesPrice.vue";
import { useProfessionalStore } from "~/stores/professional";

export default {
  components: { CardProfilePricesPrice },
  setup() {
    const professionalStore = useProfessionalStore();
    return {
      prices: professionalStore.prices,
    };
  },
  data() {
    return {
      toShowCount: 5,
    };
  },
  computed: {
    visiblePrices() {
      return this.prices.slice(0, this.toShowCount);
    },
  },
  methods: {
    showMore() {
      this.toShowCount += 5;
    },
  },
};
</script>
