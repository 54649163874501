import { DateTime } from "luxon";
/*
 * This is a workaround to the limitations posed by Vue, Webpack and Nuxt.
 *
 * Why not plugin?
 *
 * Having to pass Vue context as a parameter could be avoided by writing this
 * as a plugin and registering the helper methods globally.
 * This would cause Luxon to be bundled for every page though
 * which is an undesired funtionality.
 *
 *
 * Why not plugin with Lazy/Dynamic loading?
 *
 * Since Vue can't render async method outcomes in the template we can't work around
 * this with Dynamic/Lazy importing either as it would further complicate the logic
 * needed in the SFCs, namely having to set values in created/mounted hooks in watchers.
 *
 */

/**
 * @param {Vue} context - the Vue context
 * @param {iso8601 | DateTime} date - accepts both future and past time
 *
 * @returns Localized verbal difference to currentTime null in case of error
 */
export function differenceToNow(context, date) {
  try {
    return DateTime.fromISO(date).setLocale(context.$i18n.locale).toRelative();
  } catch (err) {
    context.$sentry.captureException(err, {
      tags: {
        datefns: "differenceToNow",
      },
    });
    return null;
  }
}

/**
 * @param {Vue} context - the Vue context
 * @param {iso8601 | DateTime} date - accepts both future and past time
 *
 * @returns Localized verbal difference to currentTime null in case of error
 */
export function daysAgo(context, date) {
  try {
    if (dateDifference(context, date, DateTime.local(), "days").days < 1) {
      return differenceToNow(context, date);
    }
    return DateTime.fromISO(date)
      .set({ hours: 0, minutes: 0 })
      .setLocale(context.$i18n.locale)
      .toRelative();
  } catch (err) {
    context.$sentry.captureException(err, {
      tags: {
        datefns: "daysAgo",
      },
    });
    return null;
  }
}
// /**
//  *
//  * @param {Vue} context - the Vue context
//  * @param {iso8601 | DateTime} fromDate
//  * @param {iso8601 | DateTime} toDate
//  * @param {*} diffOptions - https://moment.github.io/luxon/#/math?id=diffs
//  */
export function dateDifference(context, fromDate, toDate, diffOptions) {
  try {
    return DateTime.fromISO(toDate)
      .diff(DateTime.fromISO(fromDate), diffOptions)
      .toObject();
  } catch (err) {
    context.$sentry.captureException(err, {
      tags: {
        datefns: "dateDifference",
      },
    });
    return null;
  }
}
